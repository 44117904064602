<template>
  <div class="member-index">
    <div class="avatar">
      <img src="@/assets/avatar.jpg" />
      <!-- <el-upload
        class="avatar-uploader"
        action="/user/upload"
        :show-file-list="false"
        :on-success="handleAvatarSuccess"
        :before-upload="beforeAvatarUpload"
      >
        <el-avatar :size="120" :src="userinfo.avatar"></el-avatar>
        <div class="camera-wrapper">
          <svg-icon iconClass="camera"></svg-icon>
        </div>
      </el-upload>
      <p>注：尺寸为200*200px，JPG、PNG、GIF格式，小于1M，GIF不得使用动画</p> -->
    </div>
    <el-form label-width="80px">
      <el-form-item label="账号ID">
        <div class="flex-space">
          <!-- <template v-if="editInfo">
            <el-input v-model="editForm.UserNo" placeholder="请输入账号ID" maxlength="30"></el-input>
            <el-icon name="check" @click.native="handleConfirName"></el-icon>
            <el-icon name="close" @click.native="editInfo = false"></el-icon>
          </template> -->
          <template>
            <span>{{ userinfo.UserNo }}</span>
            <span
              v-show="!editInfo"
              class="info-operate"
              @click="handleEditInfo"
              >编辑</span
            >
            <div v-if="editInfo">
              <span @click="confirmEdit" class="info-operate">确认</span>
              <span
                class="c-p"
                @click="editInfo = false"
                style="margin-left: 8px"
                >取消</span
              >
            </div>
          </template>
        </div>
      </el-form-item>
      <el-form-item label="手机号">{{ userinfo.phone }}</el-form-item>
      <el-form-item label="昵称">
        <div class="flex-space">
          <template v-if="editName && !editInfo">
            <el-input
              v-model="editForm.UserName"
              placeholder="请输入昵称"
              maxlength="30"
            ></el-input>
            <el-icon name="check" @click.native="handleConfirName"></el-icon>
            <el-icon name="close" @click.native="editName = false"></el-icon>
          </template>
          <template v-else>
            <span>{{ userinfo.UserName }}</span>
            <span class="c-p" @click="handleEditName" v-show="!editInfo"
              >修改昵称</span
            >
          </template>
        </div>
      </el-form-item>
      <el-form-item label="密码">
        <div class="flex-space" v-if="userinfo.PassWord">
          <span>******</span>
          <span v-show="!editInfo" class="c-p" @click="setPassword"
            >修改密码</span
          >
        </div>
        <div class="flex-space" v-else>
          <template v-if="editLinePassword && !editInfo">
            <el-input
              v-model="passwordForm.pwd"
              placeholder="请输入密码"
              maxlength="30"
            ></el-input>
            <el-icon name="check" @click.native="handleConfirPwd"></el-icon>
            <el-icon
              name="close"
              @click.native="editLinePassword = false"
            ></el-icon>
          </template>
          <template v-else>
            <span></span>
            <span v-show="!editInfo" class="c-p" @click="setLinePassword"
              >设置密码</span
            >
          </template>
        </div>
      </el-form-item>
      <el-form-item label="微信">
        <div class="flex-space">
            <span>{{ userinfo.wechat?'已绑定':'' }}</span>
            <span class="c-p" @click="handleBandWechat" v-show="!editInfo && !userinfo.wechat"
              >绑定微信</span
            >
        </div>
      </el-form-item>
      <el-form-item label="QQ">
        <template v-if="!editInfo">
          {{ userinfo.qq }}
        </template>
        <template v-else>
          <el-input v-model="editForm.qq"></el-input>
        </template>
      </el-form-item>

      <el-form-item label="邮箱"
        ><template v-if="!editInfo">
          {{ userinfo.email }}
        </template>
        <template v-else>
          <el-input v-model="editForm.email"></el-input> </template
      ></el-form-item>
      <el-form-item label="硬币">
        <div class="flex-space">
          <span>{{ userinfo.money }}</span>
          <span v-show="!editInfo" class="c-p" @click="onWithdraw">提现</span>
        </div>
      </el-form-item>
      <!-- <el-form-item label="昵称">
        <div class="flex-space">
          <template v-if="editInfo">
            <el-input v-model="editForm.UserName" placeholder="请输入昵称" maxlength="30"></el-input>
            <el-icon name="check" @click.native="handleConfirName"></el-icon>
            <el-icon name="close" @click.native="editInfo = false"></el-icon>
          </template>
          <template v-else>
            <span>{{ userinfo.UserName }}</span>
            <span class="info-operate" @click="handleEditInfo">修改昵称</span>
          </template>
        </div>
      </el-form-item> -->
    </el-form>
    <el-dialog
      :visible.sync="editPassword"
      title="修改密码"
      center
      width="450px"
      destroy-on-close
    >
      <el-form
        label-width="90px"
        :rules="passwordRules"
        :model="passwordForm"
        ref="passwordRef"
      >
        <el-form-item label="旧密码" prop="pwd">
          <el-input
            type="password"
            v-model="passwordForm.pwd"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="NewPwd">
          <el-input
            type="password"
            v-model="passwordForm.NewPwd"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="ReNewPwd">
          <el-input
            type="password"
            v-model="passwordForm.ReNewPwd"
            show-password
          ></el-input>
        </el-form-item>
        <!-- <el-form-item>
          
        </el-form-item> -->
      </el-form>
      <template #footer>
        <el-button type="primary" @click="confirmPwd">确定</el-button>
        <el-button @click="editPassword = false">取消</el-button>
      </template>
    </el-dialog>

    <el-dialog
      :visible.sync="beforeVisible"
      title="提现设置"
      center
      width="450px"
      destroy-on-close
    >
      <el-form
        label-width="85px"
        :rules="beforeRules"
        :model="beforeForm"
        ref="beforeRef"
      >
        <el-form-item label="真实姓名" prop="truename">
          <el-input type="text" v-model="beforeForm.truename"></el-input>
        </el-form-item>
        <el-form-item label="银行卡号" prop="bank">
          <el-input type="text" v-model="beforeForm.bank"></el-input>
        </el-form-item>
        <el-form-item label="银行名称" prop="BelongsBank">
          <template #label>
            <span class="custom-label"
              >银行名称<span class="tips-label">所属支行</span></span
            >
          </template>
          <el-input type="text" v-model="beforeForm.BelongsBank"></el-input>
        </el-form-item>
        <!-- <el-form-item>
          
        </el-form-item> -->
      </el-form>
      <template #footer>
        <el-button type="primary" @click="confireBefore">确定</el-button>
        <el-button @click="beforeVisible = false">取消</el-button>
      </template>
    </el-dialog>

    <el-dialog
      :visible.sync="withdrawVisible"
      title="提现"
      center
      width="450px"
      destroy-on-close
    >
      <el-form
        label-width="85px"
        :rules="withdrawRules"
        :model="withdrawForm"
        ref="withdrawRef"
      >
        <el-form-item label="硬币" prop="">
          <el-input type="text" disabled v-model="userinfo.money"></el-input>
        </el-form-item>
        <el-form-item label="提现硬币" prop="money">
          <el-input-number
            :min="0"
            :max="userinfo.money"
            type="text"
            v-model="withdrawForm.money"
            controls-position="right"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="收款账号" prop="">
          <p>{{ userinfo.truename }}</p>
          <span>{{
            userinfo.bank ? getStr(userinfo.bank.toString()) : ""
          }}</span>
        </el-form-item>
        <el-form-item label="">
          PS: 提现硬币需大于等于100才可提现，1硬币=1元
        </el-form-item>
        <!-- <el-form-item>
          
        </el-form-item> -->
      </el-form>
      <template #footer>
        <el-button
          type="primary"
          :disabled="withdrawForm.money < 100"
          @click="confireWithdraw"
          >确定</el-button
        >
        <el-button @click="withdrawVisible = false">取消</el-button>
        <el-button @click="beforeResetBank">重置卡号</el-button>
      </template>
    </el-dialog>
    <!-- <el-row :gutter="10">
      <el-col :span="8">
        <el-card shadow="never" :body-style="{ padding: '0px' }">
          <div class="card-item">
            <div class="card-header">
              <svg-icon iconClass="coin" style="color: #f22020"></svg-icon>
              <span>硬币</span>
            </div>
            <div class="card-content">
              <p>
                <b>{{ userinfo.money }}</b>个
              </p>
            </div>
            <div class="card-footer">
              <span class="c-p" v-pending>充值</span>
              <span class="c-p" v-pending>提现</span>
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :span="8">
        <el-card shadow="never" :body-style="{ padding: '0px' }">
          <div class="card-item">
            <div class="card-header">
              <svg-icon iconClass="score" style="color: #f60"></svg-icon>
              <span>积分</span>
            </div>
            <div class="card-content">
              <p>
                <b>{{ userinfo.score }}</b>个
              </p>
            </div>
            <div class="card-footer">
              <span class="c-p" v-pending>赚取</span>
              <span class="c-p" v-pending>兑换</span>
              <span class="c-p" v-pending>商城</span>
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row> -->
    <el-dialog height="500px" width="550px"       append-to-body
      center :visible.sync="qrcodeVisible">
      <iframe :src="qrPath" width="500px" height="420px"></iframe>
      <!-- <VueQr :text="qrPath" :size="200"></VueQr> -->
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import {
  updatePassword,
  setUserInfo,
  setUserName,
  setWithdraw,
  withdraw,
  getWxLoginCode
} from "../../api";
// import SvgIcon from '../../components/SvgIcon.vue';
// import store from '../../store'

export default {
  // components: { SvgIcon },
  data() {
    let validRepet = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.passwordForm.NewPwd) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    let validBank = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入银行卡号"));
      } else if (!/^([1-9]{1})(\d{15}|\d{16}|\d{18})$/.test(value)) {
        callback(new Error("请输入正确的银行卡号!"));
      } else {
        callback();
      }
    };
    return {
      // userinfo: null,
      editName: false,
      editInfo: false,
      editForm: {
        UserName: "",
        qq: "",
        wechat: "",
        email: "",
      },
      editPassword: false,
      editLinePassword: false,
      passwordForm: {
        pwd: "",
        NewPwd: "",
        ReNewPwd: "",
      },
      passwordRules: {
        pwd: [{ required: true, message: "请输入旧密码", trigger: "blur" }],
        NewPwd: [{ required: true, message: "请输入密码", trigger: "blur" }],
        ReNewPwd: [{ required: true, validator: validRepet, trigger: "blur" }],
      },
      beforeVisible: false,
      beforeForm: {
        truename: "",
        bank: "",
        BelongsBank: "",
      },
      beforeRules: {
        truename: [
          { required: true, message: "请输入真实姓名", trigger: "blur" },
        ],
        bank: [
          { required: true, message: "请输入银行卡号", trigger: "blur" },
          { validator: validBank, trigger: "blur" },
        ],
        BelongsBank: [
          { required: true, message: "请输入银行名称", trigger: "blur" },
        ],
      },
      withdrawVisible: false,
      withdrawForm: {
        money: 0,
      },
      withdrawRules: {
        money: [
          { required: true, message: "请输入提现硬币数量", trigger: "blur" },
        ],
      },
      qrcodeVisible: false,
      qrPath: '',
    };
  },
  computed: {
    defaultAddress() {
      let isExist = this.userinfo.address?.find((item) => item.isDefault);
      if (isExist) {
        return `${isExist.address} ${isExist.name} ${isExist.phone}`;
      }
      return "";
    },
    ...mapGetters(["userinfo"]),
  },
  mounted() {
    console.log("this.userinfo >>", this.userinfo);
  },
  // created() {
  //   this.userinfo = JSON.parse(JSON.stringify(store.getters.userinfo))
  // },
  methods: {
    handleBandWechat() {
      this.qrcodeVisible = true;
      this.generateQR()
    },
    generateQR() {
      const {href} = this.$router.resolve({
        path: '/auth?codeType=bind'
      })
      getWxLoginCode({
        // url: href,
        url: `${location.origin}/${href}`
      }).then((res) => {
        console.log('res >>', res);
        this.qrPath = res
      })
    },
    beforeResetBank() {
      this.$confirm("确定要重置卡号吗？", "提示").then(() => {
        setWithdraw({
          truename: "",
          bank: "",
          BelongsBank: "",
        }).then(async () => {
          this.$message.success("重置成功");
          this.withdrawVisible = false;
          await this.$store.dispatch("getUserInfo");
        });
      });
    },
    getStr(val) {
      return val.substr(0, 4) + "***" + val.substr(-4);
    },
    confireWithdraw() {
      this.$refs.withdrawRef.validate((val) => {
        if (val) {
          withdraw(this.withdrawForm).then(async () => {
            this.$message.success("提现成功");
            this.withdrawVisible = false;
            await this.$store.dispatch("getUserInfo");
          });
        } else {
          this.$message.warning("请认真填写内容！");
        }
      });
    },
    confireBefore() {
      this.$refs.beforeRef.validate((val) => {
        if (val) {
          setWithdraw(this.beforeForm).then(async () => {
            this.$message.success("设置成功");
            this.beforeVisible = false;
            this.withdrawForm = {
              money: 0,
            };
            this.withdrawVisible = true;
            await this.$store.dispatch("getUserInfo");
          });
        } else {
          this.$message.warning("请认真填写内容！");
        }
      });
    },
    onWithdraw() {
      if (!this.userinfo.truename) {
        this.beforeForm = {
          truename: "",
          bank: "",
          BelongsBank: "",
        };
        this.beforeVisible = true;
      } else {
        this.withdrawForm = {
          money: 0,
        };
        this.withdrawVisible = true;
      }
    },
    confirmPwd() {
      this.$refs.passwordRef.validate((val) => {
        if (val) {
          updatePassword(this.passwordForm).then(() => {
            this.$message.success("修改成功");
            this.editPassword = false;
          });
        } else {
          this.$message.warning("请认真填写内容！");
        }
      });
    },
    setPassword() {
      this.passwordForm = {
        pwd: "",
        NewPwd: "",
        ReNewPwd: "",
      };
      this.editPassword = true;
    },
    setLinePassword() {
      this.passwordForm = {
        pwd: "",
        NewPwd: "",
        ReNewPwd: "",
      };
      this.editLinePassword = true;
    },
    handleConfirPwd() {
      if (!this.passwordForm.pwd) {
        this.$message.info("密码不能为空");
        return;
      }
      updatePassword({
        pwd: this.passwordForm.pwd,
      }).then(() => {
        this.editLinePassword = false;
        this.userinfo.PassWord = "set";
        this.$message.success("设置成功");
      });
    },
    confirmEdit() {
      if (this.editForm.qq && !/[1-9][0-9]{4,}/.test(this.editForm.qq)) {
        this.$message.error("qq号码格式有误，请重新输入");
        return;
      }
      if (
        this.editForm.email &&
        !/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(
          this.editForm.email
        )
      ) {
        this.$message.error("邮箱格式有误，请重新输入");
        return;
      }

      setUserInfo(this.editForm).then(() => {
        this.editInfo = false;
        this.$message.success("编辑成功");
        this.$store.dispatch("getUserInfo");
      });
    },
    handleEditInfo() {
      // this.editForm.UserNo = this.userinfo.UserNo
      this.editForm.qq = this.userinfo.qq;
      this.editForm.wechat = this.userinfo.wechat;
      this.editForm.email = this.userinfo.email;
      this.editInfo = true;
    },
    handleEditName() {
      // this.editForm.UserNo = this.userinfo.UserNo
      this.editForm.UserName = this.userinfo.UserName;
      this.editName = true;
    },
    handleCopy() {
      this.$message.success("复制成功");
    },
    handleConfirName() {
      if (!this.editForm.UserName) {
        this.$message.info("用户昵称不能为空");
        return;
      }
      setUserName({
        UserName: this.editForm.UserName,
      }).then(() => {
        this.editName = false;
        this.userinfo.UserName = this.editForm.UserName;
      });
    },
    handleAvatarSuccess(res) {
      this.userinfo.avatar = res.result.data.image;
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isLt2M;
    },
  },
};
</script>

<style lang="scss" scoped>
.member-index {
  padding: 10px 20px;
  background: #fff;
  min-height: 561px;
  .flex-space {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .avatar-uploader {
    width: 120px;
    height: 120px;
    overflow: hidden;
    position: relative;
    border-radius: 50%;
    .camera-wrapper {
      position: absolute;
      height: 20px;
      background-color: rgba(0, 0, 0, 0.6);
      width: 100%;
      bottom: 0;
      color: #f2f2f2;
    }
  }
  .avatar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px;
    img {
      width: 120px;
      border-radius: 50%;
    }
    p {
      margin-top: 30px;
      color: #777;
    }
  }
  ::v-deep {
    .el-icon-check,
    .el-icon-close {
      margin: 0 5px;
      cursor: pointer;
      font-size: 20px;
    }
    .el-icon-check {
      color: #52e209;
    }
    .el-icon-close {
      color: #eb2c2c;
    }
  }
  .info-operate {
    cursor: pointer;
    color: #eb2c2c;
  }
  .custom-label {
    position: relative;
  }
  .tips-label {
    position: absolute;
    left: 0px;
    top: 10px;
    font-size: 12px;
    color: #999;
  }
}
.card-item {
  display: flex;
  // height: 200px;
  flex-direction: column;
  .card-header {
    padding: 20px 0 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .svg-icon {
      font-size: 36px;
    }
    span {
      margin-top: 6px;
      color: #666;
      font-size: 14px;
    }
  }
  .card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 70px;
    p {
      color: #777;
      margin-bottom: 10px;
    }
    b {
      font-size: 22px;
      color: #444;
    }
    span {
      font-size: 18px;
      color: #444;
    }
  }
  .card-footer {
    height: 42px;
    border-top: 1px solid #ededed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    span {
      flex: 1;
      text-align: center;
      color: #666;
      font-size: 14px;
    }
  }
}
.c-p {
  cursor: pointer;
}
</style>
